<template>
<div class="h-screen flex w-full bg-img p-10">
    <div class="vx-col sm:w-full md:w-full lg:w-1/2 xl:w-1/4 mx-auto self-center">
        <vx-card title="Boat Harbour Park">
            <div class="vx-row">
                <div class="w-full p-4">
                    <div class="mb-8 text-center">
                        <h4 class="mb-4">Ballot Date: {{ currentBallot.ballotDate | formatLongDate }}</h4>
                        <h5 class="mb-4 text-success" v-if="optedInShow">You have successfully opted In.</h5>
                        <h5 class="mb-4 text-danger" v-if="optedoutShow">You have opted Out.</h5>
                        <p class="mb-base"></p>
                        <vs-button type="border" @click.prevent="cancel" class="w-full mt-base">Login</vs-button>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</div>
</template>

<script>
import {
    ballotData
} from "../../store/api/ballot";

import {
    customMessages,
    passwordValidationMessage
} from './../../filters/validationmessage'

export default {
    data() {
        return {
            optedInShow: false,
            optedoutShow: false,
            currentBallot: null,
            customMessages,
            passwordValidationMessage
        }
    },
    async created() {
        await this.loadBallot();
        await this.takeAction();

    },
    methods: {
        cancel() {
            this.$router.push("/logout");
        },
        async loadBallot() {
            const data = this.$route.params;
            this.currentBallot = await ballotData.SearchBallotByRequestKey(data.id)
            console.log(this.currentBallot);
        },
        async takeAction() {
            const data = this.$route.params;
            let result = await ballotData.OptInOrOptOut(data.id, data.action);
            if (!result.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: result.message,
                    color: "danger",
                    position: "top-center"
                });
                this.$router.push("/login");
                return;
            }

            this.$vs.notify({
                title: "Success",
                text: "Your request has been submitted",
                color: "success",
                position: "top-center"
            });

            if (data.action === "optin") {
                this.optedInShow = true;
                this.optedoutShow = false;
            }
            if (data.action === "optout") {
                this.optedInShow = false;
                this.optedoutShow = true;
            }
        }
    }
}
</script>

<style scoped>
.custom-logo-size {
    height: 5rem;
}
</style>
